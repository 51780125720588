import { AdvertisementDataModel } from '@models/advertisement-data';
import { useQuery } from '@tanstack/react-query';

import { api } from '@apis/hc';

interface Props {
  lat: number | null;
  lng: number | null;
}

export const useGetRegionCodeByLocationQuery = ({ lat, lng }: Props) => {
  const queryResult = useQuery({
    queryKey: ['region', lat, lng],
    queryFn: async () => {
      const response = await api.search['region-code'].$get({
        query: { coord: `${lng},${lat}` },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch region code');
      }

      return response.json();
    },
    enabled: !!lat && !!lng,
  });

  return queryResult;
};

export const useGetAdvertisementInfoByLocation = ({ lat, lng }: Props) => {
  const { data: regionCodeData } = useGetRegionCodeByLocationQuery({ lat, lng });

  const queryResult = useQuery({
    queryKey: ['advertisement', lat, lng],
    queryFn: async () => {
      const response = await api.ad.current.v2.$get({
        query: { regionCode: regionCodeData?.regionCode ?? '' },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch advertisement info');
      }

      const result = response.json();

      return result;
    },
    select: (data) => {
      // 'select'는 'queryFn'의 결과를 가공할 때 사용
      // 필요하다면 여기서 가공 작업을 추가
      if (data.data) {
        return new AdvertisementDataModel({
          adAcademy: data.data,
          userLocation: {
            bubCode: regionCodeData?.regionCode || '',
            dongName: regionCodeData?.dongName,
            siGunGuName: regionCodeData?.siGunGuName || '',
          },
        });
      }
      return null;
    },
    enabled: !!regionCodeData?.dongName && !!regionCodeData?.regionCode && !!lat && !!lng,
  });

  return queryResult;
};
